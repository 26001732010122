import React, { useState, useEffect } from "react"
import axios from "axios"
import bgPopUpImage from '../assets/images/landing/joint-tickets/bg-main-2.jpg'
import rhPopUpImage from '../assets/images/landing/joint-tickets/rh-main-3.jpg'
import { navigate } from "gatsby"
import { useCookies } from 'react-cookie'

const PopUp = ({ }) => {
    const [popupState, setPopupState] = useState({
        enable: false,
        show: false,
        image: "",
        text: "",
        linkText: "",
        link: "",
        delay: 10
    })
    const [cookies, setCookie] = useCookies(['popupShown'])
    const cookieExpiry = Math.floor(Date.now() / 1000) + (60 * 60 * 24)

    useEffect(() => {
        if (cookies['popupShown'] === true) {
            return
        }
        
        const getPopup = () => {
            axios.get(
                process.env.GATSBY_POPUP_ENDPOINT
            ).then(function (response) {
                if (response.data.enable !== true) {
                    return
                }

                const timer = setTimeout(() => {
                    setPopupState({
                        enable: response.data.enable,
                        show: true,
                        image: response.data.image,
                        text: response.data.text,
                        linkText: response.data.linkText,
                        link: response.data.link,
                        delay: response.data.delay
                    })

                    document.body.classList.add('pop-up-open')
                    document.body.style.overflow = 'hidden'

                }, (response.data.delay * 1000))

                setCookie('popupShown', true, { path: '/', expires: new Date(cookieExpiry * 1000) })
                return () => clearTimeout(timer)
            }).catch(function (error) {
                console.log(error)
            })
        }
        getPopup()
    }, [])

    const closePopUp = () => {
        setPopupState({ ...popupState, show: false })
        document.body.classList.remove('pop-up-open')
        document.body.style.overflow = 'auto'
    }

    const followLinks = () => {
        closePopUp()
        navigate(popupState.link)
    }

    return (
        <React.Fragment>
            {(popupState.enable === true && popupState.show === true) &&
                <div className="pop-up-overlay" role="dialog" aria-modal="true" data-headlessui-state="open">
                    <div className="pop-up-container bg-primary">
                        <div className="pop-up-header">
                            <a className="pop-up-close" onClick={closePopUp}>x</a>
                        </div>
                        <div className="pop-up-content text-white">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    {popupState['image'] ?
                                        <img src={popupState.image.url} className="pop-up-image" width="224" height="89" alt={popupState.image.alt}></img>
                                        :
                                        <img src={bgPopUpImage} className="pop-up-image" width="224" height="89" alt="Blackgang Chine FVP Popup"></img>
                                    }
                                </div>
                                <div className="col-12 col-md-6 pb-4 pb-md-1 pop-up-text text-center d-flex flex-column justify-content-center">
                                    {popupState.text &&
                                        <h4>{popupState.text}</h4>
                                    }
                                    {popupState.link &&
                                        <a onClick={followLinks} className="btn btn-secondary text-primary mt-3">{popupState.linkText}</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default PopUp